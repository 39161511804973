<template>
  <div class="admin-container" v-if="data">
    <v-row class="justify-space-between">
      <v-col cols="12" md="4">
        <h1 class="col-secondary-1">Timeline</h1>
      </v-col>
      <v-col
        align-self="center"
        v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'"
      >
        <v-row class="justify-md-end">
          <v-col align-self="center" md="2" class="pa-0 flex-0 mt-8 mt-md-0">
            <v-btn
              x-small
              tile
              color="secondary"
              height="32"
              @click="$router.push({ name: 'Timeline-new' })"
              ><v-icon small color="primary">mdi-plus</v-icon> Nová etapa</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <h1 v-if="!data.length" class="mt-10 text-center">
      Žádné etapy nenalezeny ..
    </h1>
    <v-timeline class="mt-4" dense clipped v-if="data.length">
      <div class="mb-10 show-more-timeline-btn">
        <v-btn
          class="mt-10"
          tile
          color="secondary"
          x-small
          height="32"
          @click="showMore"
          >{{ showingMore ? 'Zobrazit méně' : 'Zobrazit vše' }}</v-btn
        >
      </div>
      <v-timeline-item
        class="mb-6"
        v-for="item in data"
        :key="item.id"
        small
        :color="`${item.passed ? 'primary' : 'grey'}`"
      >
        <!-- icon-color="grey lighten-2" -->
        <v-row>
          <v-col cols="12" md="5">
            <v-card
              class="pa-4 d-flex flex-column timeline-card-single-item"
              style="height: 100%"
              :class="`${
                item.passed
                  ? 'bg-secondary-2 timeline-passed' +
                    (item.status === 'Probíhá' ? ' border-mymd' : '')
                  : 'bg-white timeline-not-passed'
              }`"
            >
              <v-row
                style="cursor: pointer"
                @click="
                  $router.push({
                    name: 'Timeline-detail',
                    params: {
                      id: item.id,
                    },
                  })
                "
              >
                <v-col>
                  <div class="projecting-card-name">
                    <span
                      v-if="
                        user.role != 'END_USER' &&
                        user.role != 'COMPANY_CUSTOMER'
                      "
                      >{{ item.behind_timeline }}.</span
                    >
                    {{ item.name }}
                  </div>
                  <div class="projecting-card-date mt-2">
                    {{ item.supposed_term_of_begin }} -
                    {{ item.supposed_term_of_completion }}
                  </div>
                  <div class="projecting-card-editor mt-2">
                    Stav etapy: <strong>{{ item.status }}</strong>
                  </div>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  tile
                  :color="item.passed ? 'primary' : 'secondary'"
                  x-small
                  @click="
                    $router.push({
                      name: 'Timeline-detail',
                      params: {
                        id: item.id,
                      },
                    })
                  "
                  >Detail</v-btn
                >
              </v-card-actions>
            </v-card>
            <!-- <v-card
              class="timeline-height"
              tile
              :class="`${item.passed ? 'bg-secondary-2' : 'bg-secondary-3'}`"
            >
              <v-card-title style="color: white">{{ item.name }}</v-card-title>
              <v-card-text style="color: white" class="pb-0">
                {{ item.supposed_term_of_completion }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  small
                  tile
                  color="primary"
                  @click="
                    $router.push({
                      name: 'Timeline-detail',
                      params: {
                        id: item.id,
                      },
                    })
                  "
                  >Detail</v-btn
                ></v-card-actions
              >
            </v-card> -->
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import authHandler from '../api/authHandler';
import permissionDeniedHandler from '../api/permissionDeniedHandler';
import bus from '../plugins/bus';
import buildingService from '../api/buildingService';
import timelineService from '../api/timelineService';
export default {
  data() {
    return {
      data: null,
      dataCopy: null,
      showingMore: false,
    };
  },
  async created() {
    try {
      //   await this.$store.dispatch("getCurrentUser");
      if (this.getSelectedBuilding() != null) {
        await this.timelines();
        this.computeStyles();
        this.showMoreOrLessButtonEvent();
        window.addEventListener('resize', this.showMoreOrLessButtonEvent);
      } else {
        this.$router.push({ name: 'Dashboard' });
        bus.$emit('snackbar', {
          text: 'Není vybrána žádná stavba.',
          color: 'error',
        });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: 'Login' });
      permissionDeniedHandler(error, this.$router);
    }
  },
  destroyed(){
    window.removeEventListener('resize', this.showMoreOrLessButtonEvent)
  },
  methods: {
    showMoreOrLessButtonEvent() {
      const timelineCardItem = document.querySelector(
        '.timeline-card-single-item'
      );
      if (timelineCardItem) {
        console.log(
          timelineCardItem.clientWidth,
          timelineCardItem.getBoundingClientRect()
        );
        const moreBtn = document.querySelector('.show-more-timeline-btn');

        const drawerOn = document.querySelector(
          '.v-navigation-drawer--fixed.v-navigation-drawer--open'
        );

        if (moreBtn) {
          moreBtn.style.position = `relative`;
          moreBtn.style.width = `${timelineCardItem.clientWidth}px`;
          const rect = timelineCardItem.getBoundingClientRect();
          const left = drawerOn ? (rect.left - 96 / 2) - 256 : rect.left - 96 / 2;
          // moreBtn.style.left = `${rect.left - 96 / 2}px`;
          moreBtn.style.left = `${left}px`;
        }
      }
    },
    computeStyles() {
      const wrapperTimeline = document.querySelector(
        '.v-timeline.mt-4.v-timeline--dense.theme--light'
      );
      const notPassed = document.querySelectorAll(
        '.v-timeline-item__inner-dot.grey'
      );
      const passed = document.querySelectorAll(
        '.v-timeline-item__inner-dot.primary'
      );
      const totalItems = notPassed.length + passed.length;
      if (totalItems) {
        const itemHeight =
          wrapperTimeline.getBoundingClientRect().height / totalItems;

        console.log('got', totalItems, itemHeight);

        passed.forEach((el) => {
          el.style.setProperty('--height', itemHeight + 'px');
        });
        notPassed.forEach((el) => {
          el.style.setProperty('--height', itemHeight + 'px');
        });
      }
    },
    toMessages() {
      this.$router.push({
        name: 'MessagesDetail',
        params: { id: this.data.id },
      });
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem('building')) || null;
      return building;
    },
    showMore() {
      this.showingMore = !this.showingMore;
      this.timelineNewLogic();
      setTimeout(() => {
        this.computeStyles();
      }, 120);
    },

    timelineNewLogic() {
      if (this.showingMore) {
        this.data = this.dataCopy;
      } else {
        let data = [];
        const dataFuture = [];
        const dataPassed = [];
        const dataPresent = [];

        for (const t of this.dataCopy) {
          console.log(t);
          if (t.status == 'Dokončeno') dataPassed.push(t);
          if (t.status == 'Probíhá') dataPresent.push(t);
          if (t.status == 'Čeká') dataFuture.push(t);
        }
        if (dataPassed.length) data.push(dataPassed[dataPassed.length - 1]);
        if (dataPresent.length) data.push(...dataPresent);
        if (dataFuture.length) data.push(...dataFuture);
        this.data = data;
      }
    },

    async timelines() {
      try {
        bus.$emit('processing');
        const data = await timelineService.index();
        console.debug('data-timelines', data);
        this.dataCopy = data;
        this.data = data;

        this.timelineNewLogic();

        await bus.$emit('refresh_sections');

        bus.$emit('processing', false);
      } catch (error) {
        console.debug(error);
        bus.$emit('processing', false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit('snackbar', {
            text: error.response.data.error.message,
            color: 'error',
          });
        }
      }
    },
    // async getBuildings() {
    //   try {
    //     bus.$emit("processing");
    //     const data = await buildingService.index();
    //     console.log(data);
    //     this.data = data;
    //     bus.$emit("processing", false);
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    phone() {
      return {
        backgroundImage: `url(${require('@/assets/phone.svg')} !important`,
      };
    },
    email() {
      return {
        backgroundImage: `url(${require('@/assets/email.svg')} !important`,
      };
    },
    www() {
      return {
        backgroundImage: `url(${require('@/assets/www.svg')} !important`,
      };
    },
  },
};
</script>

<style></style>
