var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data)?_c('div',{staticClass:"admin-container"},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h1',{staticClass:"col-secondary-1"},[_vm._v("Timeline")])]),(_vm.user.role != 'END_USER' && _vm.user.role != 'COMPANY_CUSTOMER')?_c('v-col',{attrs:{"align-self":"center"}},[_c('v-row',{staticClass:"justify-md-end"},[_c('v-col',{staticClass:"pa-0 flex-0 mt-8 mt-md-0",attrs:{"align-self":"center","md":"2"}},[_c('v-btn',{attrs:{"x-small":"","tile":"","color":"secondary","height":"32"},on:{"click":function($event){return _vm.$router.push({ name: 'Timeline-new' })}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Nová etapa")],1)],1)],1)],1):_vm._e()],1),(!_vm.data.length)?_c('h1',{staticClass:"mt-10 text-center"},[_vm._v(" Žádné etapy nenalezeny .. ")]):_vm._e(),(_vm.data.length)?_c('v-timeline',{staticClass:"mt-4",attrs:{"dense":"","clipped":""}},[_c('div',{staticClass:"mb-10 show-more-timeline-btn"},[_c('v-btn',{staticClass:"mt-10",attrs:{"tile":"","color":"secondary","x-small":"","height":"32"},on:{"click":_vm.showMore}},[_vm._v(_vm._s(_vm.showingMore ? 'Zobrazit méně' : 'Zobrazit vše'))])],1),_vm._l((_vm.data),function(item){return _c('v-timeline-item',{key:item.id,staticClass:"mb-6",attrs:{"small":"","color":("" + (item.passed ? 'primary' : 'grey'))}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column timeline-card-single-item",class:("" + (item.passed
                ? 'bg-secondary-2 timeline-passed' +
                  (item.status === 'Probíhá' ? ' border-mymd' : '')
                : 'bg-white timeline-not-passed')),staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                  name: 'Timeline-detail',
                  params: {
                    id: item.id,
                  },
                })}}},[_c('v-col',[_c('div',{staticClass:"projecting-card-name"},[(
                      _vm.user.role != 'END_USER' &&
                      _vm.user.role != 'COMPANY_CUSTOMER'
                    )?_c('span',[_vm._v(_vm._s(item.behind_timeline)+".")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"projecting-card-date mt-2"},[_vm._v(" "+_vm._s(item.supposed_term_of_begin)+" - "+_vm._s(item.supposed_term_of_completion)+" ")]),_c('div',{staticClass:"projecting-card-editor mt-2"},[_vm._v(" Stav etapy: "),_c('strong',[_vm._v(_vm._s(item.status))])])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","color":item.passed ? 'primary' : 'secondary',"x-small":""},on:{"click":function($event){return _vm.$router.push({
                    name: 'Timeline-detail',
                    params: {
                      id: item.id,
                    },
                  })}}},[_vm._v("Detail")])],1)],1)],1)],1)],1)})],2):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }